<!--
 * @Descripttion: -
 * @version: -
 * @Author: wjq
 * @Date: 2022-01-17 15:28:45
 * @LastEditors: wjq
 * @LastEditTime: 2022-01-18 14:30:28
-->
<template>
  <div class="error">
    <div class="error-img"></div>
    <div class="error-msg">{{ errMsg }}</div>
  </div>
</template>

<script>
export default {
  name: 'error',
  props: {
    errMsg: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="less" scoped>
.error {
  padding-top: 30vh;
}
.error-img {
  width: 218px;
  height: 110px;
  background: url('../assets/images/error-img.png');
  background-size: cover;
  margin: 0 auto;
}
.error-msg {
  margin-top: 14px;
  font-size: 14px;
  font-weight: 500;
  color: #666666;
  text-align: center;
}
</style>
