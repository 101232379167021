/*
 * @Author: your name
 * @Date: 2020-12-03 10:27:37
 * @LastEditTime: 2020-12-03 11:42:25
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \online-sale-h5\src\util\util.js
 */
import { getDeviceInfo } from '@zstark/device';
import md5 from 'js-md5';
// 文档 https://www.npmjs.com/package/@zstark/device
// device.getDeviceInfo()
/*
{
  "deviceType": "PC",
  "OS": "Windows",
  "OSVersion": "10.0",
  "language": "zh-CN",
  "orientation": "h",
  "browserInfo": {
    "info": "Chrome(版本: 91.0.4472.114, 内核: Blink)",
    "browser": "Chrome",
    "engine": "Blink",
    "browserVersion": "91.0.4472.114"
  }
}
*/
// 获取设备
export const deviceInfo = getDeviceInfo();
// 屏幕
export const screenType = {
  v: "竖屏",
  h: "横屏"
};

const ua = navigator.userAgent;
// Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_2 like Mac OS X) AppleWebKit/603.2.4 (KHTML, like Gecko) Mobile/14F89
const isPad = () => ua.indexOf('iPad') > -1;
const isIpod = () => ua.indexOf('iPod') > -1;
const iphone = () => ua.indexOf('iPhone') > -1;

export const isAndroid = () => ua.indexOf('Android') > -1 || ua.indexOf('Linux') > -1;
export const isIos = () => isPad() || isIpod() || iphone();
export const getRoleFlag = () => {
  const role = JSON.parse(localStorage.getItem('crmRole')) || {};
  return role.saleRoleName && role.saleRoleName === '置业顾问' ? 0 : 1;
};
/**
     * 解析hash模式URL传参
     * @param {Object} key
     */
export function getQueryString(key) {
  var after = window.location.search;
  //key存在先通过search取值如果取不到就通过hash来取
  after = window.location.hash.split('?')[1] || after.substr(1);
  if (after && after !== '') {
    var reg = new RegExp(`(^|&)${key}=([^&]*)(&|$)`);
    var r = after.match(reg);
    if (r != null) {
      return decodeURIComponent(r[2]);
    }
    else {
      return '';
    }
  }
}

// 防抖函数
export function debounce(fn, delay) {
  let timer = null;
  return function () {
    if (timer) {
      clearTimeout(timer);
    }
    if (!timer) {
      fn.apply(this, arguments);
    }
    timer = setTimeout(() => {
      timer = null;
    }, delay);
  };
}

// 节流
export function throttle(fn, delay) {
  let begin = 0;
  return function () {
    let current = new Date().getTime();
    if (current - begin > delay) {
      fn.apply(this, arguments);
      begin = current;
    }
  };
}

/**
 * 生成签名
 * @param {Object} params 
 * @returns 
 */
export function creatSign(params={}) {
  const arr = [];
  const arrStr = [];
  const salt = '61Ue7$g2#KNOm5i5';
  // 把key放在一个数组里面
  for (let item of Object.keys(params)) {
      arr.push(item);
  }
  // 数组重新排序
  arr.sort();
  // 重新组成参数
  for (let item of arr) {
      const value = params[item];
      arrStr.push(`${item}=${value}`);
  }
  const tempStr = `${arrStr.join('&')}${salt}`;
  return md5(tempStr);
}
