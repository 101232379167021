<!--
 * @Descripttion: -
 * @version: -
 * @Author: wjq
 * @Date: 2022-01-17 15:28:45
 * @LastEditors: wjq
 * @LastEditTime: 2022-01-18 10:50:44
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style lang="less" scoped>
@import './assets/h5.css';
*{
-webkit-overflow-scrolling: touch;
}
#app {
  height: 100vh;
  //overflow: auto;
  background-color: #F5F6F7;
  font-size: 14px;
}
</style>
<style type="text/css">
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
