<template>
  <van-popup v-model="showPop" @close="close">
    <div class="cm-flex-col card-v">
      <img class="card-bg" src="@/assets/images/ewmbj@2x.png" alt="" />
      <div class="cm-flex-col card-body">
        <div class="card-top"></div>
        <img class="card-head" :src="head" alt="" />
        <img class="card-jpgw" src="@/assets/images/jpgw.png" alt="" />
        <span class="card-name">{{ name }}</span>
        <div class="cm-flex-col card-ewm">
          <img v-if="ewmUrl" class="ewm-img" :src="ewmUrl+'?x-oss-process=image/resize,limit_0,m_fill,w_300,h_300'" alt="" />
        </div>
        <span class="bottom-text">长按识别二维码</span>
        <span class="bottom-text">添加顾问企业微信</span>
        <div class="cm-flex-row card-close">
          <img class="card-close-icon" @click="close" src="@/assets/images/close-1@2x.png" alt="" />
        </div>
        <div class="card-bottom"></div>
      </div>
    </div>
  </van-popup>
</template>

<script>
export default {
  name: 'EwmCard',
  props: {
    head: {
      type: String,
      default: '',
    },
    ewmUrl: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '待完善',
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show() {
      this.showPop = this.show;
    },
  },
  data() {
    return {
      showPop: false,
    };
  },
  mounted() {
    this.showPop = this.show;
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="less" scoped>
.card-v {
  position: relative;
  align-items: center;
  width: 264px;
  .card-bg {
    position: absolute;
    top: 20px;
    left: 0px;
    width: 264px;
    height: 350px;
  }

  .card-body {
    position: relative;
    align-items: center;
    .card-top {
      height: 30px;
      width: 264px;
    }
    .card-bottom {
      height: 100px;
      width: 264px;
    }
    .card-name {
      font-size: 16px;
      margin-top: 8px;
      color: #1d2541;
    }

    .card-head {
      object-fit: cover;
      margin-top: -30px;
      width: 60px;
      height: 60px;
      border: 2px solid #ffffff;
      border-radius: 33px;
    }

    .card-jpgw {
      margin-top: -14px;
      width: 49px;
      height: 14px;
    }

    .card-ewm {
      align-items: center;
      margin-top: 16px;
      margin-bottom: 30px;
      width: 180px;
      height: 180px;
      justify-content: center;
      background-color: #ffffff;
      border-radius: 8px;
      box-shadow: 0px 2px 10px 0px rgba(48, 49, 51, 0.2);

      .ewm-img {
        width: 160px;
        height: 160px;
      }
    }

    .bottom-text {
      font-size: 12px;
      color: #7f8393;
      line-height: 18px;
    }

    .card-close {
      position: absolute;
      align-items: center;
      bottom: 10px;
      left: 113px;
      justify-content: center;
      width: 40px;
      height: 40px;

      .card-close-icon {
        width: 18px;
        height: 18px;
      }
    }
  }
}
</style>

<style>
.van-popup {
  background-color: transparent !important;
}
</style>
