/*
 * @Date: 2022-01-17 10:26:00
 * @LastEditors: wjq
 * @LastEditTime: 2022-01-24 16:50:04
 * @Description:
 * @version:
 * @Author: seostar
 */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import Vant from 'vant';
import vueLazyload from 'vue-lazyload';
import 'vant/lib/index.less';
import '../src/components/index.js';
import * as utils from '@/util/util';
import { Popup } from 'vant';
import { bus } from '@/util/bus';

import VideoPlayer from 'vue-video-player';
import 'vue-video-player/src/custom-theme.css';
import 'video.js/dist/video-js.css';
Vue.use(VideoPlayer);

Vue.use(Popup);
//全局组件注入
//移动端调试vconsole注入
import VConsole from 'vconsole';

if (utils.getQueryString('debug') && utils.getQueryString('debug') === '1') {
  new VConsole();
}
Vue.prototype.$bus = bus;
Vue.use(Vant);
Vue.use(vueLazyload);
Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app');

Vue.config.ignoredElements = [
  'wx-open-launch-app',
  'wx-open-launch-weapp'
]

// 引入 skywalking
import ClientMonitor from 'wxb-skywalking-client-js';
const domain = location.hostname;
const oap = {
  test: 'https://sky.test.wangxiaobao.com',
  prod: 'https://sky.wangxiaobao.com'
}
let env = 'prod'
if(domain.indexOf('.test.') !== -1){
  env = 'test'
}
else if(domain.indexOf('.dev.') !== -1){
  env = 'dev'
}
else if(/^(localhost|(\d{1,3}\.){3}\d{1,3})$/i.test(domain)){
  env = 'local'
}

if (oap[env]) {
  ClientMonitor.register({
    collector: oap[env],
    service: 'cardh5', //前端静态项目的名称
    pagePath: 'index.html',
    serviceVersion: 'v1.0.0', //版本号（如果是要区分哪次前端页面版本，可以修改此参数，在错误日志管理里面该参数会有体现）
    vue: Vue,
    useFmp: true,
    traceTimeInterval: 10000, //上报频率（毫秒），无接口请求的时候是不会进行上报动作的，可根据自己项目接口请求情况自行调整频率
    enableSPA: true,
    noTraceOrigins: ['https://vectorsdk.map.qq.com', 'https://aegis.qq.com', 'https://mapstyle.qpic.cn']
  });
}
